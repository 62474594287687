var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Histórico de alterações")]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-tabs',{attrs:{"background-color":"primary","center-active":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"tab_turma"},[_vm._v("Extrato de alterações")]),_c('v-tab',{key:"tab_turma_aluno_atividade"},[_vm._v("Buscar alteração")])],1),_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"})]),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"tab_turma"},[_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","multi-sort":"","headers":_vm.operationsHeadersTurma,"items":_vm.turma_itens,"loading":_vm.loading,"loading-text":"Carregando...","footer-props":{
                                          itemsPerPageOptions: [-1]
                                      },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(item.created_at))+" ")]}},{key:"item.aluno_id",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAlunoById(item.aluno_id))+" ")]}},{key:"item.atividade_id",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAtividadeById(item.atividade_id))+" ")]}},{key:"item.alteracao",fn:function(ref){
                                      var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getAlteracao(item))}})]}}],null,true)})],1)],1)],1)],1),_c('v-tab-item',{key:"tab_turma_aluno_atividade"},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.propAtividades,"item-text":"descricao","item-value":"id","label":"Atividade"},on:{"change":function($event){return _vm.onChangeValues()}},model:{value:(_vm.atividadeId),callback:function ($$v) {_vm.atividadeId=$$v},expression:"atividadeId"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.propAlunos,"label":"Aluno","item-text":"aluno_nome","item-value":"id"},on:{"change":function($event){return _vm.onChangeValues()}},model:{value:(_vm.alunoId),callback:function ($$v) {_vm.alunoId=$$v},expression:"alunoId"}})],1)],1),_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","multi-sort":"","headers":_vm.operationsHeaders,"items":_vm.log,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
                                      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){_vm.showDetail(_vm.log.indexOf(item), _vm.log)}}},[_vm._v("mdi-eye")])]}}],null,true)})],1),_c('v-col',[_c('v-data-table',{attrs:{"dense":"","multi-sort":"","headers":_vm.detailsHeaders,"items":_vm.detailsItems,"loading":_vm.loading,"loading-text":"Carregando...","footer-props":{
                                          itemsPerPageOptions: [-1]
                                      },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.before",fn:function(ref){
                                      var value = ref.value;
return [(typeof value == 'Object')?_c('span',[_vm._v(" "+_vm._s(JSON.stringify(value))+" ")]):_vm._e(),(typeof value == 'boolean')?_c('span',[(value)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1):_c('span',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.after",fn:function(ref){
                                      var value = ref.value;
return [(typeof value == 'Object')?_c('span',[_vm._v(" "+_vm._s(JSON.stringify(value))+" ")]):_vm._e(),(typeof value == 'boolean')?_c('span',[(value)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1):_c('span',[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }