import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { logStore } from '@/store';
let TurmaAlunoAtividadeLogComponent = class TurmaAlunoAtividadeLogComponent extends Vue {
    constructor() {
        super(...arguments);
        this.operationsHeaders = [
            {
                text: 'Usuário',
                sortable: true,
                value: 'created_by_name',
                align: 'left',
            },
            {
                text: 'Data',
                sortable: true,
                value: 'created_at',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.operationsHeadersTurma = [
            {
                text: 'Data',
                sortable: true,
                value: 'created_at',
                align: 'left',
            },
            {
                text: 'Usuário',
                sortable: true,
                value: 'created_by_name',
                align: 'left',
            },
            {
                text: 'Aluno',
                sortable: true,
                value: 'aluno_id',
                align: 'left',
            },
            {
                text: 'Atividade',
                sortable: true,
                value: 'atividade_id',
                align: 'left',
            },
            {
                text: 'Alteração',
                sortable: true,
                value: 'alteracao',
                align: 'left',
            },
        ];
        this.detailsHeaders = [
            {
                text: 'Campo',
                sortable: true,
                value: 'field',
                align: 'left',
            },
            {
                text: 'Antes',
                sortable: true,
                value: 'before',
                align: 'left',
            },
            {
                text: 'Depois',
                sortable: true,
                value: 'after',
                align: 'left',
            },
        ];
        this.atividadeId = null;
        this.alunoId = null;
        this.loading = false;
        this.dialog = false;
        this.detailsItems = [];
        this.operationsItems = [];
        this.log = [];
        this.tab = 0;
        this.turma_itens = [];
    }
    getAlteracao(item) {
        let reposicao_string = '';
        if (['Presença'].includes(item.tipo)) {
            reposicao_string = `${item.tipo} - ${item.presenca == 'true' ? 'presente' : 'falta'}`;
        }
        if (['Agendamento de reposição'].includes(item.tipo) ||
            !!item.reposicao_agendada) {
            reposicao_string += ` ${reposicao_string != '' ? '<br/>' : ''} Agendamento de reposição - ${item.reposicao_agendada == 'true' ? 'Marcado' : 'Removido'} `;
        }
        if (['Conclusão de reposição'].includes(item.tipo) ||
            !!item.reposicao_concluida) {
            reposicao_string += ` ${reposicao_string != '' ? '<br/>' : ''} Conclusão de reposição - ${item.reposicao_concluida == 'true' ? 'Marcado' : 'Removido'}`;
        }
        if (['Não repor falta'].includes(item.tipo) || !!item.reposicao_nao_repor) {
            reposicao_string += ` ${reposicao_string != '' ? '<br/>' : ''} Não repor falta - ${item.reposicao_nao_repor == 'true' ? 'Marcado' : 'Removido'}`;
        }
        if (reposicao_string != '') {
            return reposicao_string;
        }
        return `Nota de ${item.tipo} - ${item.nota}`;
    }
    getAlunoById(id) {
        if (!id) {
            return;
        }
        const aluno = _.find(this.propAlunos, (item) => {
            if (item.id == id) {
                return true;
            }
        });
        if (aluno) {
            return aluno.aluno_nome;
        }
    }
    getAtividadeById(id) {
        if (!id) {
            return;
        }
        const atividade = _.find(this.propAtividades, (item) => {
            if (item.id == id) {
                return true;
            }
        });
        if (atividade) {
            return atividade.descricao;
        }
    }
    async onChangeValues() {
        this.loading = true;
        this.detailsItems = [];
        if (this.alunoId && this.atividadeId) {
            this.log = await logStore.getTurmaAlunoAtividadeLog({
                turma_id: this.propTurmaId,
                atividade_id: this.atividadeId,
                aluno_id: this.alunoId,
            });
        }
        this.loading = false;
    }
    async show() {
        this.loading = true;
        this.dialog = true;
        this.turma_itens = await logStore.getFullTurmaAlunosAtividadeLog({
            turma_id: this.propTurmaId,
        });
        this.loading = false;
    }
    async showDetail(index, log) {
        this.detailsItems = [];
        this.detailsItems = log[index].details;
    }
    async showDetailTurma(index, log) {
        this.detailsItems = [];
        // @ts-ignore
        Object.keys(log[index].data).forEach((key) => {
            let beforeValue = '';
            const size = log.length > index + 1 ? index + 1 : log.length;
            let item = null;
            if (log.length != size) {
                item = _.find(log, (i) => {
                    // @ts-ignore
                    if (i.data[key] && i.extra && log[index].extra) {
                        if (
                        // @ts-ignore
                        i.extra.aluno_id == log[index].extra.aluno_id ||
                            // @ts-ignore
                            i.extra.atividade_id == log[index].extra.atividade_id) {
                            return true;
                        }
                    }
                    // @ts-ignore
                    if (i.data[key] && i.model_id == log[index].model_id) {
                        return true;
                    }
                }, size);
            }
            // @ts-ignore
            beforeValue = item ? item.data[key] : '';
            let afterValue = '';
            // @ts-ignore
            if (log[index].data[key] !== '') {
                // @ts-ignore
                afterValue = log[index].data[key];
            }
            if (beforeValue !== afterValue) {
                if (!(typeof beforeValue == 'object' && afterValue == '') &&
                    !(typeof afterValue == 'object' && beforeValue == '')) {
                    this.detailsItems.push({
                        // @ts-ignore
                        field: key,
                        // @ts-ignore
                        before: beforeValue,
                        // @ts-ignore
                        after: afterValue,
                    });
                }
            }
        });
    }
};
__decorate([
    Prop({ type: Number })
], TurmaAlunoAtividadeLogComponent.prototype, "propTurmaId", void 0);
__decorate([
    Prop({ type: Array })
], TurmaAlunoAtividadeLogComponent.prototype, "propAtividades", void 0);
__decorate([
    Prop({ type: Array })
], TurmaAlunoAtividadeLogComponent.prototype, "propAlunos", void 0);
TurmaAlunoAtividadeLogComponent = __decorate([
    Component
], TurmaAlunoAtividadeLogComponent);
export default TurmaAlunoAtividadeLogComponent;
